import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash.get';

import './index.scss';

import { Image, Section, Container, Row, Column } from 'components/atoms';

const WidgetWorkImages = ({ data, className }) => {
  const {
    background_fill,
    background_color,
    image_type,
    images,
    same_color,
  } = data;

  const imagesColumns = images.map((item, index) => {
    const { source_url, alt_text, title, media_details } = item;

    const mobileCol = image_type === 'mobile' ? '4' : '10';

    const isOffset =
      image_type !== 'mobile' || (image_type === 'mobile' && index === 0)
        ? true
        : false;
    const isOffsetDouble =
      image_type === 'mobile' && index !== 0 ? true : false;

    let desktopCol, desktopOffset;
    if (image_type === 'mobile') {
      desktopCol = '3';
      desktopOffset = 'offset-lg-2';
    } else if (image_type === 'tablet') {
      desktopCol = '4';
      desktopOffset = index === 0 ? 'offset-md-1' : 'offset-md-2';
    } else {
      desktopCol = '5';
      desktopOffset = index === 0 ? 'offset-lg-0' : 'offset-lg-2';
    }

    return (
      <Column
        lg={desktopCol}
        md={image_type === 'tablet' ? desktopCol : null}
        col={mobileCol}
        className={cn(desktopOffset, {
          'offset-1': isOffset,
          'offset-2': isOffsetDouble,
          'widget-work-images__col': true,
          'widget-work-images__col_default': image_type === 'default',
        })}
        key={index}
        onMouseDown={e => e.preventDefault()}
        onContextMenu={e => e.preventDefault()}
      >
        <Image
          src={source_url}
          placeholder64={get(item, 'acf.placeholder_base64')}
          lazyLoading
          imageFit={'cover'}
          alt={alt_text}
          title={title}
          className={cn({
            'widget-work-images__image': true,
            'widget-work-images__image_tablet': image_type === 'tablet',
            'widget-work-images__image_mobile': image_type === 'mobile',
          })}
          image={media_details}
        ></Image>
      </Column>
    );
  });

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  return (
    <Section
      className={cn(
        'widget-work-images',
        { 'widget-work-offset-bg_fill': background_fill },
        { 'widget-work-offset-bg_same-color': same_color },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>{imagesColumns}</Row>
      </Container>
    </Section>
  );
};

WidgetWorkImages.defaultProps = {
  className: '',
};

WidgetWorkImages.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default WidgetWorkImages;
